import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Profile from "../../assets/21.png";
import Logout from "../../assets/22.png";
import Settings from "../../assets/Settings icon.png";
import Hamburger from "../../assets/18.png";

const Navbar = () => {
  const navigate = useNavigate();

  // Logout function
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem("token");
    // Redirect to the login page
    navigate("/login");
  };

  const location = useLocation(); // Hook to get the current path


  const pageTitles = {
    "/": "DASHBOARD",
    "/admin-home": "HOME PAGE",
    "/admin-hair": "HAIR TREATMENT PAGE",
    "/admin-skin": "SKIN TREATMENT PAGE",
    "/admin-testimonial": "TESTIMONIAL PAGE",
    "/admin-aboutus": "ABOUT US PAGE",
    "/admin-contact-appointment": "CONTACT & APPOINTMENT PAGE",
    "/admin-footer": "FOOTER",
    "/admin-inquires": "INQUIRES",
    "/admin-appointment": "APPOINTMENT",
  };

  return (
    <div>
      <div className="ms-auto w-5/6 h-16 flex justify-between items-center py-2 px-5 bg-gold">
        <div className="flex justify-evenly items-center space-x-4 font-semibold">
          <img src={Hamburger} className="w-6 m-auto" alt="Menu" />
          <h2>{pageTitles[location.pathname] || "DASHBOARD"}</h2> {/* Dynamic page title */}
        </div>
        <ul className="flex justify-evenly items-center text-center space-x-7 font-medium text-[13px]">
          <li>
            <div>
              <img src={Profile} className="w-6 m-auto" alt="Profile" />
              <p className="pt-1">PROFILE</p>
            </div>
          </li>
          <li>
            <div onClick={handleLogout} className="cursor-pointer"> {/* Logout click handler */}
              <img src={Logout} className="w-6 m-auto" alt="Logout" />
              <p className="pt-1">LOGOUT</p>
            </div>
          </li>
          <li>
            <div>
              <img src={Settings} className="w-6 m-auto" alt="Settings" />
              <p className="pt-1">SETTINGS</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
