import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";

// Import default images
import Dashboard from "../../assets/Dashboard (1).png";
import Homepage from '../../assets/Home.png';
import Treatments from '../../assets/Treatments (2).png';
import Testimonial from '../../assets/testimonials.png';
import Aboutus from '../../assets/About.png';
import Contact from '../../assets/Dashboard (8).png';
// import Navbar from '../../assets/17.png';
import Footer from '../../assets/19.png';
import Inquires from '../../assets/inquiry.png';
import Appointment from '../../assets/Appointmnet.png';

// Import hover images
import DashboardHover from "../../assets/9.png";
import HomepageHover from "../../assets/10.png";
import TreatmentsHover from "../../assets/11.png";
import TestimonialHover from "../../assets/12.png";
import AboutusHover from "../../assets/13.png";
import ContactHover from "../../assets/16.png";
// import NavbarHover from "../../assets/18.png";
import FooterHover from "../../assets/20.png";
import InquiresHover from "../../assets/15.png";
import AppointmentHover from "../../assets/14.png";

const Sidebar = () => {
  const location = useLocation(); // Hook to get the current path
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuItems = [
    { name: "DASHBOARD", link: "/", defaultImg: Dashboard, hoverImg: DashboardHover },
    { name: "HOME PAGE", link: "/admin-home", defaultImg: Homepage, hoverImg: HomepageHover },
    { name: "HAIR TREATMENTS PAGE", link: "/admin-hair", defaultImg: Treatments, hoverImg: TreatmentsHover },
    { name: "SKIN TREATMENT PAGE", link: "/admin-skin", defaultImg: Treatments, hoverImg: TreatmentsHover },
    { name: "TESTIMONIAL PAGE", link: "/admin-testimonial", defaultImg: Testimonial, hoverImg: TestimonialHover },
    { name: "ABOUT US PAGE", link: "/admin-aboutus", defaultImg: Aboutus, hoverImg: AboutusHover },
    { name: "CONTACT & APPOINTMENT PAGE", link: "/admin-contact-appointment", defaultImg: Contact, hoverImg: ContactHover },
    
    { name: "FOOTER", link: "/admin-footer", defaultImg: Footer, hoverImg: FooterHover },
    { name: "INQUIRES", link: "/admin-inquires", defaultImg: Inquires, hoverImg: InquiresHover },
    { name: "APPOINTMENT", link: "/admin-appointment", defaultImg: Appointment, hoverImg: AppointmentHover },
  ];

  return (
    <div>
      <div className="bg-black w-1/6 h-[100vh] z-10 absolute top-0">
        <Link to="/">
          <div className="flex space-x-2 justify-center items-center pt-3">
            <img src={Logo} alt="Logo" className="w-12" />
            <div className="text-[13px] font-semibold text-white pt-1">
              <span className="block">SKIN & HAIR</span>
              <span className="block">CLINIC</span>
            </div>
          </div>
        </Link>
        <nav>
          <ul className="flex flex-col text-white justify-center mt-5 text-[13px] font-normal">
            {menuItems.map((item, index) => (
              <Link to={item.link} key={index}>
                <li
                  className={`p-2.5 pl-6 pr-2 flex items-center space-x-4 ${
                    location.pathname === item.link
                      ? "bg-gold" // Highlight active route
                      : "hover:bg-gold"
                  }`}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <img
                    src={
                      location.pathname === item.link
                        ? item.hoverImg // Show hover image for active route
                        : hoveredItem === index
                        ? item.hoverImg
                        : item.defaultImg
                    }
                    className="w-7"
                    alt={item.name}
                  />
                  <p>{item.name}</p>
                </li>
              </Link>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
