import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import design1 from "../assets/Group 13.png";
import design2 from "../assets/Group 14.png";
import Logo from "../assets/logo.png";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await axios.post("http://localhost:5000/api/login/login-user", { email, password });
            localStorage.setItem("token", res.data.token); // Save JWT in localStorage
            navigate("/"); // Redirect to Dashboard
        } catch (err) {
            setError(err.response?.data?.error || "Login failed");
        }
    };

    return (
        <div className="h-screen bg-gold flex justify-center items-center relative">
            <div className="absolute top-0 right-0">
                <img src={design1} alt="design-block1" />
            </div>
            <div className="absolute bottom-0 left-0">
                <img src={design2} alt="design-block2" />
            </div>
            <div className="absolute top-4 left-20 flex space-x-2 items-center">
                <img src={Logo} alt="Logo" className="w-14" />
                <div className="text-sm font-bold text-gray-800 pt-1">
                    <span className="block">SKIN & HAIR</span>
                    <span className="block">CLINIC</span>
                </div>
            </div>
            <div className="bg-gold p-8 shadow-lg rounded-lg border border-black w-96">
                <h1 className="text-center text-xl font-bold mb-4 text-black">LOGIN</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-semibold mb-2 text-black">
                            User Name
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter email Id"
                            className="w-full px-4 py-2 bg-grey border border-black rounded-md"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-semibold mb-2 text-black">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            className="w-full px-4 py-2 bg-grey border border-black rounded-md"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {error && <p className="text-red-500 text-xs mb-4">{error}</p>}
                    <button type="submit" className="w-full bg-black text-gold py-2 rounded-md">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
