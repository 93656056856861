// src/routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";

// Import components
import Dashboard from "../pages/Dashboard";
import Aboutus from "../pages/Aboutus";
import Conapp from "../pages/Contact&Appointment";
import Footer from "../pages/Footer";
import Inquires from "../pages/Inquires";
import Hair from "../pages/Hair";
import Appointment from "../pages/Appointment";
import Skin from "../pages/Skin";
import Home from "../pages/Home";
import Testimonial from "../pages/Testimonial";
import Login from "../pages/Login";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-aboutus"
        element={
          <ProtectedRoute>
            <Aboutus />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-contact-appointment"
        element={
          <ProtectedRoute>
            <Conapp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-hair"
        element={
          <ProtectedRoute>
            <Hair />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-appointment"
        element={
          <ProtectedRoute>
            <Appointment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-skin"
        element={
          <ProtectedRoute>
            <Skin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-footer"
        element={
          <ProtectedRoute>
            <Footer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-inquires"
        element={
          <ProtectedRoute>
            <Inquires />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-testimonial"
        element={
          <ProtectedRoute>
            <Testimonial />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
