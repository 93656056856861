import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import ContentNav from "../components/Navbar/ContentNav";
import Navbar from "../components/Navbar/Navbar";

const Home = () => {
  const [useContent, setuseContent] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Heading"); // Default tab
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const [editRowId, setEditRowId] = useState(null);
  const [editedContent, setEditedContent] = useState({
    category: "",
    content: "",
    src: "",
    path: "",
  });

  useEffect(() => {
    const getuseContent = async () => {
      const reqContent = await fetch("http://localhost:5000/api/home/home-content");
      const resContent = await reqContent.json();
      setuseContent(resContent);
      console.log(resContent);
    };
    getuseContent();
  }, []);

  const filteredContent = useContent.filter(
    (item) => item.category.toLowerCase() === selectedTab.toLowerCase()
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredContent.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredContent.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (rowId, content) => {
    const confirmEdit = window.confirm("Are you sure you want to edit this item?");
    if (confirmEdit) {
      setEditRowId(rowId);
      setEditedContent(content);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedContent({
      ...editedContent,
      [name]: value,
    });
  };

  const handleSave = async (rowId) => {
    const confirmSave = window.confirm("Save the changes you made?");
    if(confirmSave)
    {
    try {
      const response = await fetch(
        `http://localhost:5000/api/home/update/${rowId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedContent),
        }
      );

      if (response.ok) {
        setuseContent((prevContent) =>
          prevContent.map((item) =>
            item.element_id === rowId ? { ...item, ...editedContent } : item
          )
        );
        setEditRowId(null);
      } else {
        console.error("Failed to update content");
      }
    } catch (error) {
      console.error("Error updating content:", error);
    }
  }
  };

  // Function to handle record deletion with confirmation
  const handleDelete = async (rowId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (confirmDelete) {
      try {
        const response = await fetch(
          `http://localhost:5000/api/home/delete/${rowId}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          setuseContent((prevContent) =>
            prevContent.filter((item) => item.element_id !== rowId)
          );
          console.log("Record deleted successfully");
        } else {
          console.error("Failed to delete content");
        }
      } catch (error) {
        console.error("Error deleting content:", error);
      }
    }
  };

  return (
    <div>
    <Navbar/>
      <Sidebar />
      <ContentNav onContentChange={setSelectedTab} selectedTab={selectedTab} />
      <div className="w-5/6 ms-auto overflow-auto">
        <div className="overflow-y-auto">
          <table className="m-5 w-full min-w-[1000px] text-sm text-left text-black border border-black">
            <thead className="text-xs font-bold uppercase text-black bg-gray-200">
              <tr>
                <th className="p-4 border">
                  <input type="checkbox" className="w-4 h-4" />
                </th>
                <th className="px-2 py-3 border">Content Type</th>
                <th className="px-2 py-3 border">Value</th>
                <th className="px-2 py-3 border">Src</th>
                <th className="px-2 py-3 border">Path</th>
                <th className="px-2 py-3 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((useContent) => (
                <tr key={useContent.element_id} className="border">
                  <td className="p-4 border">
                    <input type="checkbox" className="w-4 h-4" />
                  </td>
                  <td className="px-2 py-3 border">
                    {editRowId === useContent.element_id ? (
                      <input
                        type="text"
                        name="category"
                        value={editedContent.category}
                        onChange={handleChange}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      useContent.category
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editRowId === useContent.element_id ? (
                      <input
                        type="text"
                        name="content"
                        value={editedContent.content}
                        onChange={handleChange}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      useContent.content
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editRowId === useContent.element_id ? (
                      <input
                        type="text"
                        name="src"
                        value={editedContent.src}
                        onChange={handleChange}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      useContent.src
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editRowId === useContent.element_id ? (
                      <input
                        type="text"
                        name="path"
                        value={editedContent.path}
                        onChange={handleChange}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      useContent.path
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editRowId === useContent.element_id ? (
                      <button
                        onClick={() => handleSave(useContent.element_id)}
                        className="bg-green-500 text-white px-2 py-1 rounded"
                      >
                        Save
                      </button>
                    ) : (
                      <>
                        <button
                          onClick={() => handleEdit(useContent.element_id, useContent)}
                          className="bg-blue-500 text-white px-2 py-1 rounded"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(useContent.element_id)}
                          className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center space-x-2 mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
