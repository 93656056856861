// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Navbar from "./components/Navbar/Navbar";
import "./App.css"; 


function App() {
  return (
    <Router>
      

      {/* Render AppRoutes for route definitions */}
      <AppRoutes />
    </Router>
  );
}

export default App;
