import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

const Inquires = () => {
  const [useContent, setUseContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});

  const itemsPerPage = 10;

  // Fetch data
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const reqContent = await fetch("http://localhost:5000/api/contact/contact-us");
        const resContent = await reqContent.json();
        setUseContent(resContent);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchContent();
  }, []);

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedContent = useContent.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(useContent.length / itemsPerPage);

  // Handle edit
  const handleEdit = (index) => {
    setEditingRow(index);
    setEditedData(useContent[index]);
  };

  const handleInputChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };

  const handleSave = async (id) => {
    const confirmSave = window.confirm("Save the changes you made?");
    if(confirmSave)
    {
        try {
      const response = await fetch(`http://localhost:5000/api/contact/contact-us/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editedData),
      });
      if (response.ok) {
        const updatedContent = [...useContent];
        updatedContent[editingRow] = editedData;
        setUseContent(updatedContent);
        setEditingRow(null);
        alert("Record updated successfully!");
      } else {
        alert("Failed to update record.");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while updating the record.");
    }
  }
  };

  // Handle delete
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Save the changes you made?");
    if(confirmDelete){
    try {
      const response = await fetch(`http://localhost:5000/api/contact/contact-us/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setUseContent(useContent.filter((item) => item.id !== id));
        alert("Record deleted successfully!");
      } else {
        alert("Failed to delete record.");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      alert("An error occurred while deleting the record.");
    }
  }
  };

  return (
    <div>
    <Navbar/>
      <Sidebar />
      <div className="w-5/6 ms-auto overflow-x-auto">
        <div className="overflow-y-auto">
          <table className="m-5 w-full min-w-[1000px] text-sm text-left text-black border border-black">
            <thead className="text-xs font-bold uppercase text-black bg-gray-200">
              <tr>
                <th className="p-4 border">#</th>
                <th className="px-2 py-3 border">Name</th>
                <th className="px-2 py-3 border">Phone Number</th>
                <th className="px-2 py-3 border">Email</th>
                <th className="px-2 py-3 border">Subject</th>
                <th className="px-2 py-3 border">Message</th>
                <th className="px-2 py-3 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedContent.map((item, index) => (
                <tr key={item.id} className="border">
                  <td className="p-4 border">{startIndex + index + 1}</td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <input
                        type="text"
                        value={editedData.name}
                        onChange={(e) => handleInputChange(e, "name")}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      item.name
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <input
                        type="text"
                        value={editedData.phone}
                        onChange={(e) => handleInputChange(e, "phone")}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      item.phone
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <input
                        type="email"
                        value={editedData.email}
                        onChange={(e) => handleInputChange(e, "email")}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      item.email
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <input
                        type="text"
                        value={editedData.subject}
                        onChange={(e) => handleInputChange(e, "subject")}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      item.subject
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <input
                        type="text"
                        value={editedData.message}
                        onChange={(e) => handleInputChange(e, "message")}
                        className="border border-gray-300 p-1"
                      />
                    ) : (
                      item.message
                    )}
                  </td>
                  <td className="px-2 py-3 border">
                    {editingRow === index ? (
                      <button
                        onClick={() => handleSave(item.id)}
                        className="bg-green-500 text-white px-2 py-1 rounded"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEdit(index)}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Edit
                      </button>
                    )}
                    <button
                      onClick={() => handleDelete(item.id)}
                      className="bg-red-500 text-white px-2 py-1 rounded ml-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center space-x-2 mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`px-3 py-1 rounded ${
                currentPage === i + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Inquires;
