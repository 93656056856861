import React from "react";
import DeleteButton from "../DeleteButton";
import SaveButton from "../SaveButton";

const ContentRow = ({ row }) => {
  const handleEdit = () => {
    alert(`Editing: ${row.name}`);
  };

  return (
    <tr className="border-b border-black">
      <td className="p-4">
        <input type="checkbox" className="w-4 h-4" />
      </td>
      <td className="px-6 py-4">{row.name}</td>
      <td className="px-6 py-4">{row.value}</td>
      <td className="px-6 py-4 flex space-x-2">
        <button
          onClick={handleEdit}
          className="text-blue-600 hover:underline"
        >
          Edit
        </button>
        <DeleteButton rowId={row.id} />
      </td>
    </tr>
  );
};

export default ContentRow;
