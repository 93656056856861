import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import Navbar from '../components/Navbar/Navbar'

const Dashboard = () => {


  return (
    <div>
    <Navbar/>
      <Sidebar/>
    </div>
  )
}

export default Dashboard