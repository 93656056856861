import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

const Appointment = () => {
  const [useContent, setuseContent] = useState([]);
  const [editingRecordId, setEditingRecordId] = useState(null);
  const [currentRecordData, setCurrentRecordData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  useEffect(() => {
    const getuseContent = async () => {
      const reqContent = await fetch(
        "http://localhost:5000/api/appointment/book_an_appointment"
      );
      const resContent = await reqContent.json();
      setuseContent(resContent);
    };
    getuseContent();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = useContent.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(useContent.length / recordsPerPage);

  // Handle edit button click
  const handleEdit = (record) => {
    setEditingRecordId(record.id);
    setCurrentRecordData(record);
  };

  // Handle input change for inline editing
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setCurrentRecordData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  // Handle save button click to update the record
  const handleSave = async () => {
    const confirmSave = window.confirm("Are you sure you want to save this record?");
    if (confirmSave) {
    try {
      const response = await fetch(
        `http://localhost:5000/api/appointment/book_an_appointment/${editingRecordId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(currentRecordData),
        }
      );

      if (response.ok) {
        alert("Record updated successfully!");
        setuseContent((prev) =>
          prev.map((record) =>
            record.id === editingRecordId ? { ...record, ...currentRecordData } : record
          )
        );
        setEditingRecordId(null);
      } else {
        alert("Failed to update record.");
      }
    } catch (error) {
      console.error("Error updating record:", error);
      alert("Error updating record. Please try again.");
    }
  }
  };

  // Delete Record
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this record?");
    if (confirmDelete) {
      try {
        await fetch(`http://localhost:5000/api/appointment/book_an_appointment/${id}`, {
          method: "DELETE",
        });
        setuseContent(useContent.filter((record) => record.id !== id));
        alert("Record deleted successfully!");
      } catch (error) {
        console.error("Error deleting record:", error);
        alert("Failed to delete record. Please try again.");
      }
    }
  };

  // Pagination buttons
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
    <Navbar/>
      <Sidebar />
      <div className="w-5/6 ms-auto">
        <div className="overflow-x-auto overflow-y-auto">
          <table className="m-5 w-full min-w-[1000px] text-sm text-left text-black border border-black">
            <thead className="text-xs font-bold uppercase text-black bg-gray-200">
              <tr>
                <th className="p-4 border">Select</th>
                <th className="px-2 py-3 border">Name</th>
                <th className="px-2 py-3 border">Phone Number</th>
                <th className="px-2 py-3 border">Email</th>
                <th className="px-2 py-3 border">Location</th>
                <th className="px-2 py-3 border">Services Required</th>
                <th className="px-2 py-3 border">Date</th>
                <th className="px-2 py-3 border">Time</th>
                <th className="px-2 py-3 border">Message</th>
                <th className="px-2 py-3 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((record) => (
                <tr key={record.id} className="border-b">
                  <td className="p-4 border">
                    <input type="checkbox" className="w-4 h-4" />
                  </td>
                  {editingRecordId === record.id ? (
                    <>
                      <td className="px-1 py-3 border">
                        <input
                          type="text"
                          value={currentRecordData.name}
                          onChange={(e) => handleInputChange(e, "name")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="text"
                          value={currentRecordData.phone}
                          onChange={(e) => handleInputChange(e, "phone")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="email"
                          value={currentRecordData.email}
                          onChange={(e) => handleInputChange(e, "email")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="text"
                          value={currentRecordData.location}
                          onChange={(e) => handleInputChange(e, "location")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="text"
                          value={currentRecordData.selected_services}
                          onChange={(e) => handleInputChange(e, "selected_services")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="date"
                          value={currentRecordData.date}
                          onChange={(e) => handleInputChange(e, "date")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="time"
                          value={currentRecordData.time}
                          onChange={(e) => handleInputChange(e, "time")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <input
                          type="text"
                          value={currentRecordData.message}
                          onChange={(e) => handleInputChange(e, "message")}
                          className="border p-1"
                        />
                      </td>
                      <td className="px-1 py-3 border">
                        <button
                          onClick={handleSave}
                          className="bg-green-500 text-white px-3 py-1 rounded mr-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setEditingRecordId(null)}
                          className="bg-gray-500 text-white px-3 py-1 rounded"
                        >
                          Cancel
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-2 py-3 border">{record.name}</td>
                      <td className="px-2 py-3 border">{record.phone}</td>
                      <td className="px-2 py-3 border">{record.email}</td>
                      <td className="px-2 py-3 border">{record.location}</td>
                      <td className="px-2 py-3 border">{record.selected_services}</td>
                      <td className="px-2 py-3 border">{record.date}</td>
                      <td className="px-2 py-3 border">{record.time}</td>
                      <td className="px-2 py-3 border">{record.message}</td>
                      <td className="px-2 py-3 border">
                        <button
                          onClick={() => handleEdit(record)}
                          className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(record.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded"
                        >
                          Delete
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 mx-1 rounded ${
                currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Appointment;
