import React, { useState } from "react";
import ContentRow from "../components/ContentTable/ContentRow";
import Sidebar from "../components/Sidebar/Sidebar";
import ContentNav from "../components/Navbar/ContentNav";
import Navbar from "../components/Navbar/Navbar";

const Testimonial = () => {
  const [currentPage, setCurrentPage] = useState(1); // Tracks current page
  const [selectedContent, setSelectedContent] = useState("Heading"); // Tracks selected content

  const rowsPerPage = 7; // Number of rows per page (minimum 10)
  const allData = {
    Heading: [
      { id: 1, name: "Heading 1", value: "Title 1" },
      { id: 2, name: "Heading 2", value: "Title 2" },
      { id: 3, name: "Heading 3", value: "Title 3" },
      { id: 4, name: "Heading 4", value: "Title 4" },
      { id: 5, name: "Heading 5", value: "Title 5" },
      { id: 6, name: "Heading 6", value: "Title 6" },
      { id: 7, name: "Heading 7", value: "Title 7" },
      { id: 8, name: "Heading 8", value: "Title 8" },
      { id: 9, name: "Heading 9", value: "Title 9" },
      { id: 10, name: "Heading 10", value: "Title 10" },
      { id: 11, name: "Heading 11", value: "Title 11" },
    ],
    SubHeading: [
      { id: 1, name: "SubHeading 1", value: "Subtitle 1" },
      { id: 2, name: "SubHeading 2", value: "Subtitle 2" },
      { id: 3, name: "SubHeading 3", value: "Subtitle 3" },
      { id: 4, name: "SubHeading 4", value: "Subtitle 4" },
      { id: 5, name: "SubHeading 5", value: "Subtitle 5" },
      { id: 6, name: "SubHeading 6", value: "Subtitle 6" },
      { id: 7, name: "SubHeading 7", value: "Subtitle 7" },
      { id: 8, name: "SubHeading 8", value: "Subtitle 8" },
      { id: 9, name: "SubHeading 9", value: "Subtitle 9" },
      { id: 10, name: "SubHeading 10", value: "Subtitle 10" },
    ],
    Paragraph: [
      { id: 1, name: "Paragraph 1", value: "This is a paragraph." },
      { id: 2, name: "Paragraph 2", value: "This is another paragraph." },
      { id: 3, name: "Paragraph 3", value: "Paragraph content here." },
      { id: 4, name: "Paragraph 4", value: "Additional content here." },
      { id: 5, name: "Paragraph 5", value: "Another example here." },
      { id: 6, name: "Paragraph 6", value: "Yet another paragraph." },
      { id: 7, name: "Paragraph 7", value: "This is paragraph seven." },
      { id: 8, name: "Paragraph 8", value: "Paragraph eight here." },
      { id: 9, name: "Paragraph 9", value: "More paragraph content." },
      { id: 10, name: "Paragraph 10", value: "Final example here." },
    ],
    Images: [],
    Videos: [],
  };

  const totalRows = allData[selectedContent]?.length || 0;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) setCurrentPage(newPage);
  };

  const handleContentChange = (contentType) => {
    setSelectedContent(contentType);
    setCurrentPage(1);
  };

  const currentRows = allData[selectedContent]?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <div>
    <Navbar/>
      <Sidebar />
      <ContentNav
        onContentChange={handleContentChange}
        selectedTab={selectedContent}
      />
      <div className="w-5/6 ms-auto">
        <table className="m-5 w-[96%] text-sm text-left text-black border border-black">
          <thead className="text-xs font-bold uppercase text-black bg-gray-200">
            <tr>
              <th className="p-4">
                <input type="checkbox" className="w-4 h-4" />
              </th>
              <th className="px-6 py-3">Content Name</th>
              <th className="px-6 py-3">Value</th>
              <th className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRows?.map((row) => (
              <ContentRow key={row.id} row={row} />
            ))}
          </tbody>
        </table>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
