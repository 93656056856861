import React from "react";

const DeleteButton = ({ rowId }) => {
  const handleDelete = () => {
    alert(`Deleted row with ID: ${rowId}`);
  };

  return (
    <button
      onClick={handleDelete}
      className="text-red-600 hover:underline"
    >
      Delete
    </button>
  );
};

export default DeleteButton;
