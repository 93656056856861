import React from "react";

const ContentNav = ({ onContentChange, selectedTab }) => {
  const tabs = ["Heading", "SubHeading", "Paragraph", "List-Items", "Image", "Video"];

  return (
    <div className="w-5/6 ms-auto">
      <ul className="text-black font-normal flex items-center text-sm bg-gold">
        {tabs.map((tab) => (
          <li
            key={tab}
            onClick={() => onContentChange(tab)}
            className={`px-10 py-3 rounded-t-md cursor-pointer 
              ${selectedTab === tab ? "bg-white text-gold" : "bg-gold hover:bg-white hover:text-gold"}
            `}
          >
            {tab.toUpperCase()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentNav;
